.inputRow {
    display: flex;
    flex-direction: column;
}

.inputRow div {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin: 5px 0;
}

.inputError { 
    color: red;
}

@media(min-width: 768px) {
    .inputRow div {
        width: 50%;
    }
}

@media(min-width: 992px) {
    .inputRow {
        flex-direction: row;
        justify-content: space-evenly;
    }
    .inputRow div {
        display: block;
        width: 100%;
    }
    .inputRow label {
        padding-right: 5px;
    }
}