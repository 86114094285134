.search-wrapper {
    display: flex;
}

.search-inputs {
    border: 0px;
    border-radius: 10px 0px 0px 10px;
    padding: 3px 5px;
    background-color: #fff;
    color: black;
}

.search-wrapper input {
    border: none;
}

.search-type {
    border: none;
}

.search-wrapper button {
    line-height: 25px;
    border-left: 0px;
    border-radius: 0px 10px 10px 0px;
}

.search-wrapper button:focus {
    background-color: white;
    color: black;
    opacity: 0.7;
}