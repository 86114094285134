.weaknessTable {
    width: 100%;
  }

  .typeWeaknessData {
      display: flex;
  }
  
  .typeLabel, .damageMultiplier {
    border: 1px solid black;
    text-align: center;
    font-family: Helvetica, Arial, sans-serif;
  }

  .damageMultiplier {
      width: 100%;
      color: black;
  }

  :global(.body-wrapper.dark-mode) .damageMultiplier.default {
    color: white;
    opacity: 0.8;
  }

  .damageMultiplier.zero {
    background-color: black;
    color: white;
  }

  .damageMultiplier.quarter {
    background-color: blue;
    color: white;
  }

  .damageMultiplier.half {
    background-color: purple;
    color: white;
  }

  .damageMultiplier.double {
    background-color: yellow;
    color: black;
  }

  .damageMultiplier.quadruple {
    background-color: red;
    color: white;
  }
  
  .weaknessTable .dataCell {
    padding: 5px;
    background-color: white;
    width: 50%;
  }
  
  .weaknessTable .typeLabel {
    border: 0 none;
    background: linear-gradient(#333 0%,#444 100%);
    color: white;
    padding: 5px;
    width: 100%;
  }

  @media(min-width: 992px) {
    .weaknessTable {
        display: flex;
    }
    .typeWeaknessData {
        flex-direction: column;
    }
    .typeLabel {
        width: 50%;
    }
}