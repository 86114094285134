.overlay {
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: auto;
    text-align: center;
}

.loadingText {
    color: #fff;
}

.dotOne {
    opacity: 0;
    animation-name: dotOneAnim;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes dotOneAnim {
    0% { opacity: 0 }
    16% { opacity: 1 }
}

.dotTwo {
    opacity: 0;
    animation-name: dotTwoAnim;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes dotTwoAnim {
    33% { opacity: 0 }
    49% { opacity: 1 }
}

.dotThree {
    opacity: 0;
    animation-name: dotThreeAnim;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes dotThreeAnim {
    66% { opacity: 0 }
    82% { opacity: 1 }
}