.sidebar {
    position: fixed;
    width: 100%;
    padding: 0px 5px;
    height: 100px;
    background: #160032;
}

.sidebar {
  padding: 1rem 0;
  text-align: end;
}

@media(min-width: 992px) {
    .sidebar {
        width: 20%;
        height: 100vh;

    }
}

.sidebar ul {
    list-style-type: none;
}

.sidebar button {
    font: inherit;
    cursor: pointer;
    padding: 1rem 2rem;
    border: 1px solid #40005d;
    background-color: #e91c24;
    color: #f4f4f4;
    border-radius: 12px;
    margin-right: 1rem;
    font-weight: 700;
  }

  :global(.body-wrapper.dark-mode) .sidebar button {
    background-color: #40148c;
    border-color: #40148c;
  }
  
  .sidebar button:hover,
  .sidebar button:active {
    background-color: #000;
    border-color: #000;
  }

  :global(.body-wrapper.dark-mode) .sidebar button:hover,
  .sidebar button:active {
    border: 1px solid #000;
    background-color: #000;
  }
  
  .sidebar button.alternative {
    color: #220131;
    border-color: transparent;
    background-color: transparent;
  }
  
  .sidebar button.alternative:hover,
  .sidebar button.alternative:active {
    background-color: #ddb3f8;
  }