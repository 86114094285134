.expenses-filter {
    padding: 0 1rem;
}

.expenses-filter__control {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0 1rem;
}

.expenses-filter__heading {
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #111111;
}

:global(.body-wrapper.dark-mode) .expenses-filter__heading {
    color: #fff;
}

.expenses-filter__control li {
    width: 25%;
}

@media(min-width: 992px) {
    .expenses-filter__control li {
        width: 50%;
    }
}