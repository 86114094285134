.expenses {
  padding: 1rem;
  background-color: #f4f4f4;
  width: 95%;
  margin: 100px auto auto auto;
}

:global(.body-wrapper.dark-mode) .expenses {
  background-color: #000;
}

@media(min-width: 992px) {
  .expenses {
    display: flex;
    flex-wrap: wrap;
    width: 75%;
    margin: 0 0 0 20%;
  }
  .expenses__data {
    width: 66%;
  }
}
