.cardWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    text-align: center;
}

.cardWrapper h1 {
    font-size: 56px;
    font-family: 'Luxurious Script', cursive;
}

.cardWrapper p {
    font-size: 22px;
    font-style: italic;
}

.cardWrapper span {
    font-size: 28px;
    font-family: 'Lily Script One', cursive;
}

.animationWrapper {
    position: relative;
    width: 350px;
    height: 350px;
}

@keyframes toggleVisibilityTwo {
    0% {
      visibility: visible;
    }
    50% {
      visibility: hidden;
    }
}

@keyframes toggleVisibilityFour {
    0% {
      visibility: visible;
    }
    25% {
      visibility: hidden;
    }
}

.animationPanel img {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border: 2px solid black;
    border-radius: 5px;
}

.fourPanelFirst {
    animation-name: toggleVisibilityFour;
    animation-duration: 2.8s;
    animation-iteration-count: infinite;
}

.fourPanelSecond {
    animation-name: toggleVisibilityFour;
    animation-duration: 2.8s;
    animation-iteration-count: infinite;
    animation-delay: 0.7s;
}

.fourPanelThird {
    animation-name: toggleVisibilityFour;
    animation-duration: 2.8s;
    animation-iteration-count: infinite;
    animation-delay: 1.4s;
}

.fourPanelFourth {
    animation-name: toggleVisibilityFour;
    animation-duration: 2.8s;
    animation-iteration-count: infinite;
    animation-delay: 2.1s;
}

.twoPanelFirst {
    animation-name: toggleVisibilityTwo;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.twoPanelSecond {
    animation-name: toggleVisibilityTwo;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 1s;
}