.filters-list__filters:not(.expanded) {
    display: none;
}

.filters-list__collapse {
    padding: 1rem 0;
    display: flex;
    justify-content: flex-end;
}

.filters-list__collapse button {
    font: inherit;
    cursor: pointer;
    padding: 1rem 2rem;
    border: 1px solid #40005d;
    background-color: #111111;
    color: #f4f4f4;
    border-radius: 12px;
    font-weight: 700;
}

:global(.body-wrapper.dark-mode) .filters-list__collapse button {
    background-color: #40148c;
}

.filter-date {
    display: flex;
    align-items: flex-end;
}

.sort-by {
    display: flex;
    align-items: flex-end;
}

@media(min-width: 992px) {
    .filters-list {
        width: 33%;
        order: 2;
    }

    .filters-list__collapse {
        justify-content: center;
    }

    .sort-by {
        display: block;
    }

    .filter-date {
        display: block;
    }
}