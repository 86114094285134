.category-filters {
    position: relative;
    margin: 20px auto;
    padding: 0 5px;
    width: 90%;
}
.category-filters::after {
    content: '';
    display: block;
    width: 100%;
    border-bottom: 1px solid rgba(0,0,0,0.3);
}

.category-filters__toggle {
    position: absolute;
    text-align: center;
    left: 50%;
    bottom: -15px;
}

.category-filters__toggle button {
    position: relative;
    left: -50%;
}

.categories ul {
    display: flex;
}