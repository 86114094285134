.cascade {

}

.emptyCascade {
    width: 100px;
    height: 150px;
    border: 1px solid #000;
    border-radius: 5px;
    background: linear-gradient(rgba(0,0,0,0), rgba(255,255,255,0.5));
}

.cardWrapper:not(:last-child) {
    height: 25px;
    overflow: hidden;
}