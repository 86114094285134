#header-brand {
  font-size: 2rem;
}

#header-brand:hover {
  text-decoration: underline;
}

.welcome-text-left span {
  font-size: 5em;
  margin-bottom: 0.5rem;
  font-weight: 300;
  line-height: 6rem;
  float: right;
  padding-right: 5%;
  color: darkorange;
  font-family: 'PT Sans', sans-serif;
  animation: 3s ease-out 0s 1 slideInFromLeft;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-80%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

#quick-links>span {
  animation: 3s ease-out 0s 1 slideInFromRight;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(80%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#education-wrapper {
  padding-top: 3%;
  padding-left: 15%;
  padding-right: 22%;
  width: 90%;
  transition: opacity 1s ease-in;
  opacity: 0;
}

#education-wrapper p {
  color: black;
}

.education-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}

.education-info:nth-child(even)  {
  align-items: flex-end;
}

.education-info .school-description {
  font-weight: 600;
}

.skill-badge:not(.rotate-badge) {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.skill-badge img {
  transition: padding-left 1s ease-out, transform 0.4s ease-out;
  padding-left: 500px;
}

.skill-badge:not(.rotate-badge) img {
  padding-left: 0px;
  transform: rotate(0deg);
}

.skill-badge:not(.rotate-badge) p {
  height: 0px;
  opacity: 0;
  padding-left: 35%;
  cursor: default!important;
}

.rotate-badge img {
  transform: rotate(90deg);
}

.rotate-badge p {
  transition: opacity 1s 0.2s ease-in;
  padding-left: 0px;
  height: 100%;
  opacity: 1!important;
}

.PhotoGalleryApp {
  background-color: rgba(0,0,0,0.65);
}

.body-wrapper:not(.dark-mode) .PhotoGalleryApp {
  background-color: transparent;
}

.body-wrapper:not(.dark-mode) .PhotoGalleryApp h3, .body-wrapper:not(.dark-mode) .PhotoGalleryApp p {
  color: rgba(0,0,0, 0.7);
}

.custom-btn__primary {
  border-radius: 5px;
  background-color: #574aac;
  border: 1px solid #574A71;
  color: white;
  line-height: 23px;
  padding: 0px 20px;
  cursor: pointer;
}

.custom-btn__primary:hover {
  background-color: #574A71;
}

.intro-content {
  margin: auto;
  max-width: 85%;
  border-radius: 12px;
  text-align: center;
}

.bg-cross-right {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background: linear-gradient(180deg, #a599e6, #8b77ff);
  transform: skewY(-7deg);
  opacity: 0.5;
  height: 300px;
  width: 100%;
  z-index: 0;
}

.bg-cross-left{
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      background: linear-gradient(
  180deg, #a599e6, #8b77ff);
      transform: skewY(
  11deg);
      opacity: 0.7;
      height: 300px;
      width: 100%;
      z-index: 0;
}

.container {
  padding-top: 5px;
  padding-bottom: 5px;
}

footer {
  position: relative;
}