.gameboard {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
}

.gameRow {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
}

.bomb {
    text-align: center;
    height: 15px;
    width: 15px;
    border: 0;
    margin: 0;
    padding: 0;
}

.gameCell {
    border: 2px outset;
    background-color: gray;
    line-height: 0.9375rem;
    text-align: center;
}

.gameCell:hover {
    background-color: blue;
}

.gameCell.activeCell {
    border: 1px solid white;
    background-color: lightgray;
    padding: 1px;
}

.cellVal0 {
    color: rgba(0,0,0,0);
 }
 
 .cellVal1 {
     color: blue;
 }
 
 .cellVal2 {
     color: green;
 }
 
 .cellVal3 {
     color: darkorange;
 }
 
 .cellVal4 {
     color: purple;
 }
 
 .cellVal5 {
     color: red;
 }
 
 .cellVal6 {
     color: darkred;
 }

 .flagged {
     color: red;
 }