.gallery-options {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    position: relative;
}

.gallery-options__row {
    display: flex;
    flex-direction: column;
}

.gallery-options__row > * {
    margin: auto;
    margin-bottom: 10px;
}

@media(min-width: 768px) {
    .gallery-options__row {
        flex-direction: row;
        justify-content: space-evenly;
    }
}