.photo-card {
    width: 90%;
    display: flex;
    border-radius: 5px;
    padding: 1rem;
    margin: 5px auto;
    position: relative;
}

.photo-card:not(.Grid):nth-child(n+2):before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background: linear-gradient(
180deg, #a599e6, transparent);
    opacity: 0.5;
    height: 80%;
    width: 100%;
}

.photo-card.Grid {
    width: 50%;
    flex-direction: column;
}

@media(min-width: 768px) {
    .photo-card.Grid {
        width: 25%;
    }
}

.photo-card__image {
    width: 40%;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    position: relative;
}

:global(body:not(.dark-mode)) .photo-card__image {
    background-color: rgba(0, 0, 0, 0.3);
}

.photo-card.Grid .photo-card__image {
    width: 95%;
    margin: auto;
}

  .photo-card__image img {
    max-width: 102%;
    max-height: 90%;
    margin: 10% auto;
  }

.photo-card__info {
    width: 56%;
    padding: 2%;
    z-index: 1;
}

.photo-card.Grid .photo-card__info {
    width: 95%;
    margin: auto;
}

.photo-card__categories {
    display: flex;
    flex-wrap: wrap;
}

.photo-card:nth-child(even).List .photo-card__image, .photo-card:nth-child(odd).List .photo-card__info {
    order: 1;
}

.photo-card:nth-child(odd).List .photo-card__image, .photo-card:nth-child(even).List .photo-card__info {
    order: 2;
}
