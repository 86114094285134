.tabButton {
    background-color: #008cba;
    color: white;
    border: 1px solid black;
    margin: 0;
    margin-bottom: 5px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.tabButton:hover {
    opacity: 0.7;
    cursor: pointer;
}

.tabButton:global(.active) {
    background-color: orange;
    color: white;
    border: 1px solid darkorange;
}

.tabButton:global(.active)::after {
    font-family: 'Font Awesome\ 5 Free';
    content: '\f00d';
    color: white;
    font-weight: 600;
    padding-left: 4px;
}