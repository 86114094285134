.chart {
    padding: 1rem;
    border-radius: 12px;
    background-color: #fefefe;
    text-align: center;
    display: flex;
    justify-content: space-around;
    height: 10rem;
  }

  :global(.body-wrapper.dark-mode) .chart {
    background-color: #333;
  }