.expense-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  margin: 1rem 0;
  background-color: #fefefe;
}

:global(.body-wrapper.dark-mode) .expense-item {
  background-color: #333;
}

.expense-item__description {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
}

.expense-item h2 {
  font-size: 1rem;
  flex: 1;
  color: #111111;
}

:global(.body-wrapper.dark-mode) .expense-item h2 {
  color: #fff;
}

.expense-item__price {
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color: #111111;
  border: 1px solid white;
  padding: 0.5rem;
  border-radius: 12px;
  width: fit-content;
}

.expense-item__price-block {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.expense-item__content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  flex: 1;
}

.expense-item__credit {
  background-color: yellow;
  border: 1px solid #f4f4f4;
  padding: 0.5rem;
  border-radius: 12px;
  color: #111111;
  font-weight: bold;
}

.expense-item__cash-back {
  background-color: green;
  border: 1px solid white;
  padding: 0.5rem;
  border-radius: 12px;
  width: fit-content;
  color: #f4f4f4;
  font-weight: bold;
}

@media (min-width: 992px) {
  .expense-item__description {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
  }
}
@media (min-width: 580px) {

  .expense-item__description h2 {
    font-size: 1.25rem;
  }
  .expense-item__price {
    font-size: 1.25rem;
    padding: 0.5rem 1.5rem;
  }
}
