.diagram {
    display: flex;
    justify-content: center;
}

.diagram img {
    max-width: 95%;
}

@media(min-width: 992px) {
    .diagram img {
        max-width: 800px;
    }    
}