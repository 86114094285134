.gameWrapper {
    display: flex;
    justify-content: center;
}

.gameButtonsWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.gameButtonsWrapper > div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.gameButtonsWrapper p {
    text-align: center;
    font-size: large;
}