.category-tag {
    margin: 2.5px 5px;
    border-radius: 15px;
    padding: 5px;
    background-color: cadetblue;
    color: #fff;
}

.category__nature {
    background-color: green;
}

.category__car {
    background-color: red;
}

.category__music {
    background-color: blue;
}