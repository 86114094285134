.card {
    width: 100px;
    height: 150px;
    border: 1px solid #000;
    border-radius: 5px;
    background-color: #fff;
}

.card img {
    max-width: 90%;
    max-height: 120px;
    border-radius: 5px;
}

.highlighted {
    background-color: aqua;
}

.redSuit {
    color: red;
}

.blackSuit {
    color: black;
}

.cardInfo > span {
    padding: 2px;
}

.rank {
    color: #000;
}

.cardFace {
    text-align: center;
}

.faceGraphic > i {
    width: 50%;
}