

.HitchhikersGuide404Wrapper {
  overflow: hidden;
  min-height: 70vh;
}

.body-wrapper:not(.dark-mode) .HitchhikersGuide404Wrapper {
  background-color: rgba(135, 206, 235, 0.3);
}

#page-not-found-notice {
    text-align: center;
    z-index: 4;
    margin: auto;
}

#page-not-found-notice p {
  font-size: 18px;
}
#page-not-found-notice h2 {
  font-family: 'Notable', sans-serif;
}

#falling-whale__text, #falling-petunias__text {
    color: #008cba;
}

.body-wrapper.dark-mode #falling-whale__text, .body-wrapper.dark-mode #falling-petunias__text {
    color: rgb(127, 190, 255);
}

.motion-indicator {
    width: 21px;
    height: 21px;
    border-left: 1px solid #333;
    position: absolute;
    background-size: 100%;
}

.body-wrapper.dark-mode .motion-indicator {
    border-color: #fff;
}

.cloud {
    width: 20%;
    height: auto;
    z-index: 2;
}

.falling-whale__svg {
  z-index: 3;
  position: absolute;
  top: 30%;
  right: 10%;
  width: 45%;
}

@media(min-width: 768px) {
  .falling-whale__svg {
    top: 40%;
    right: 10%;
    width: 45%;
  }
}

#falling-whale__svg .whale {
  fill: #040759;
}

.body-wrapper.dark-mode #falling-whale__svg .whale {
  fill: #112966;
}

.falling-petunias__svg {
  z-index: 3;
  position: absolute;
  top: 60%;
  left: 25%;
  width: 10%;
}

.comment-bubble__wrapper {
  position: absolute;
  right: -10px;
}


@media(min-width: 768px) {
  .falling-petunias__svg {
    top: 40%;
  }
}

#comment-bubble {
  position: relative;
}

#comment-bubble span {
  position: absolute;
  top: 2rem;
  left: 1.3rem;
  width: 5rem;
  color: #fff;
  z-index: 2;
}

.body-wrapper.dark-mode #comment-bubble span {
  color: #333;
}

#comment-bubble .fa-comment {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 7rem;
  z-index: 1;
}