.backdrop {
    background: rgba(0,0,0,0.5);
    z-index: 500;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.darkTheme {
    background: rgba(255, 255, 255, 0.5);
}