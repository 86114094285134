.chart-bar {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .chart-bar__inner {
    height: 100%;
    width: 100%;
    border: 1px solid #111111;
    border-radius: 12px;
    background-color: rgba(22,0,50,0.5);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  :global(.body-wrapper.dark-mode) .chart-bar__inner {
    background-color: #ddb3f8;
  }
  
  .chart-bar__fill {
    background-color: #160032;
    width: 100%;
    transition: all 0.3s ease-out;
  }

  :global(.body-wrapper.dark-mode) .chart-bar__fill {
    background-color: rgba(64, 20, 140, 0.8);
  }
  
  .chart-bar__label {
    font-weight: bold;
    font-size: 0.5rem;
    text-align: center;
  }