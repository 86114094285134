.gameWrapper {
    margin: 20px auto;
}

.topRow {
    display: flex;
}

.cardsPreview {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
}