body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-link:hover {
  cursor: pointer;
}

.body-wrapper.dark-mode a, .body-wrapper.dark-mode .btn-link {
  color: rgb(127, 190, 255);
}

.body-wrapper.dark-mode a:hover, .body-wrapper.dark-mode .btn-link:hover {
  color: rgba(127, 190, 255, 0.7);
}

#pageWrap {
  background: url('./static/images/white-waves.png');
}

#bodyContent {
  min-height: 70vh;
  font-family: 'Nunito', sans-serif;
}

.body-wrapper.dark-mode #bodyContent {
  background-color: rgba(0,0,0,0.6);
  color: #fff;
}

.header-wrapper {
  background-color: #fff;
  z-index: 9;
}

.body-wrapper.dark-mode .header-wrapper {
  background-color: #333;
}

.my-row {
  display: flex;
  width: 100%;
}

@media(max-width: 692px) {
  #bodyContent {
    min-height: 80vh;
  }
}

ul {
  list-style-type: none;
}

.hidden {
  display: none;
}

@media(min-width: 992px) {
  .mobile-btns {
    display: none;
  }
  .social {
    padding: 0rem 0rem 0rem 0.6rem;
  }
}

.mobile-header-bg {
  position: absolute;
  top: 0;
  left: 0;
  background: aliceblue;
  z-index: -1;
  height: 200px;
  width: 100%;
}

.anchor {
  position: absolute;
  top: -203px;
}

#navbar {
  background: white linear-gradient(180deg, aliceblue, white) repeat-x;
}

.body-wrapper.dark-mode #navbar {
  background: #333 linear-gradient(180deg, #112966, #333) repeat-x;
}

@media(max-width: 991px) {
  #navbar {
    display: flex;
    flex-direction: column;
    background: aliceblue;
    border-radius: 0px 0px 5px 5px;
    width: 98%;
    margin-left:1%;
    margin-right: 1%;
  }
  .header-brand-section {
    display: flex;
    width: 100%;
  }
  .social {
    padding: 1.2rem 1rem;
  }
}

.navbar-brand {
  color: black;
  font-size: 5vh;
  padding-left: 0.6rem;
}

.body-wrapper.dark-mode .navbar-brand {
  color: #fff;
}

.navbar-brand:hover {
  color: teal;
}

.fa-linkedin {
  margin-left: 10px;
}

.dropdown-menu {
  background-color: aliceblue;
}

.body-wrapper.dark-mode .dropdown-menu {
  background: #333 linear-gradient(180deg, #112966, #333) repeat-x;
}

.body-wrapper.dark-mode .dropdown-item {
  color: #fff;
}

.body-wrapper.dark-mode .dropdown-item:hover {
  background-color: #333;
}

#heading-banner-root {
  overflow: hidden;
  height: 5rem;
}

@media(max-width: 991px) {
  #heading-banner-root {
    display: none;
  }
}

#quick-links {
  background: url('./static/images/classy-fabric.png');
  border-top: 1px solid;
  border-bottom: 3px solid;
  display: flex;
  justify-content: center;
}

#quick-links a {
  color: darkgrey;
}

#quick-links a:hover {
  color: #008cba;
}

.quick-link-wrapper {
  display: flex;
  flex-direction: column;
}

.quick-link-icons {
  display: inline-flex;
}

#quick-links a:hover {
  text-decoration: none;
}

@media(max-width: 991px) {
  .welcome-text-left span, #quick-links {
    display: none;
  }
}

#introduction {
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 5%;
  background: rgba(230,190,0,0.6);
  width: 90%;
  margin-right: auto;
  animation: 2s ease-out 0s 1 introSlideFromLeft;
  opacity: 0.8;
}

#introduction p {
  font-family: 'Nunito', sans-serif;
  font-size: 1rem;
  width: 75%;
  opacity: 0.9;
  color: black;
}

.intro-overlap {
  border-radius: 3px 0px 0px 3px;
  position: fixed;
  bottom: 10%;
  right: 0%;
  width: 20%;
  height: 30%;
  background-color: rgba(0,0,255,0.5);
  animation: 2s ease-out 0s 1 introSlideFromRight;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 99;
}

.intro-overlap a {
  color: ivory;
  font-size: 1.2rem;
  padding-left: 10%;
  width: fit-content;
}

.body-wrapper.dark-mode .intro-overlap a {
  color: ivory;
}

.resume-wrapper {

  width: 100%;
}

@media(max-width: 991px) {
  #introduction {
    width: 100%;
    padding-top: 16%;
    padding-bottom: 12%;
    background: aliceblue;
    opacity: 1;
  }
  #introduction p {
    width: 90%;

    color: black;
  }
  .intro-overlap {
    display: none;
  }
}

@keyframes introSlideFromLeft {
  0% {
    transform: translateX(-80%) translateY(-40%);
    opacity: 0;
  }
  100% {
    transform: translateX(0) translateY(0);
    opacity: 0.8;
  }
}

@keyframes introSlideFromRight {
  0% {
    transform: translateX(120%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slideable {
  transition: height 0.5s ease-in;
}

@keyframes slideUp {
  0% {
    max-height: 98%;
  }
  100% {
    max-height: 0%;
  }
}

@keyframes slideDown {
  0% {
    max-height: 2%;
  }
  100% {
    max-height: 100%;
  }
}

.opacity-1 {
  opacity: 1!important;
}

#skills-info {
  padding-top: 3%;
  padding-bottom: 5%;
  width: 90%;
  margin-right: auto;
  transition: opacity 1s ease-in;
  opacity: 0;
}

#skills-info  {
  color: black;
  width: 100%;
  background: url('./static/images/lodyas.png');
  color: ivory;
  margin-top: 10px;
  padding-bottom: 5%;
}

#skills-info .heading {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 75%;
  margin-left: 5%;
}

.skill-description-wrapper {
  width: 100%;
  height: 100%;
}

.badges-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 75%;
  margin-left: 5%;
  padding-right: 2%;
}

@media(max-width: 991px) {
  .badges-row {
    margin-right: auto;
    margin-left: auto;
  }
}

.skill-badge {
  width: 30%;
  overflow: hidden;
  padding: 1%;
  margin-top: auto;
  margin-bottom: auto;

}

.skill-badge {
  border-bottom: solid 5px;
  border-top: solid 5px;
  border-color: transparent;
}

.skill-badge:hover {
  border-color: rgba(192,192,192, 0.3);
  border-radius: 5%;
}

.skill-badge img, .skill-badge p {
    cursor: pointer;
}

.no-cursor {
  cursor: default!important;
}

@media(max-width: 692px) {
  .skill-badge{
    margin-bottom: 3%;
    margin-top: 3%;
    width: 100%;
  }
  #skills-info {
    padding-left: 4%;
    padding-right: 4%;
  }
  .badges-row, #skills-info .heading {
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
}

.align-skill-img {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.skill-badge img {

  width: 75%;
  padding-left: 0px;
  vertical-align: middle;
}

.lua-badge img, .cpp-badge img {
  width: 65%;
}

.skill-badge p {
  height: 0px;
  opacity: 0;
}

.skill-p-slide {
  transition: opacity 1s 0.2s ease-in;
}

.python-badge img, .java-badge img, .jquery-badge img {
  width:100%;
}

.python-badge img, .mysql-badge img, .django-badge img, .jquery-badge img, .bootstrap-badge img  {
  border-radius: 5%;
}

.linux-badge  img, .agile-badge img {
  background: white;
  border-radius: 5%;
}

.rotateBadge {
  transition: padding-left 1s ease-out, transform 0.4s ease-out;
}

.marker {
  list-style-type: circle;
}

#experience-wrapper {
  background: url('./static/images/webb.png');
}

.experience-info {
  font-family: 'Nunito', sans-serif;
  width: 70%;
  padding-top: 5%;
  margin-left: 5%;
  padding-bottom: 5%;
}

#footer-content {
  background: black;
  color: white;
}

.footer-wrapper {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 5%;
  padding-top: 5%;
  justify-content: space-between;
}
.footer-links {
  margin-left: unset;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

@media(min-width: 692px) {
  .footer-wrapper {
    width: 95%;
    padding-top: 1%;
    padding-bottom: 1%;
  }
  
  .footer-links {
    margin-left:auto;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 26px;
  margin-bottom: 0;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.dark-mode__options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dark-mode__desc {
  font-size: 1.2rem;
  padding: 5px;
  color: #008cba;
}

.body-wrapper.dark-mode .dark-mode__desc {
  color: rgb(127, 190, 255);
}

@media(max-width: 991px) {
  .header-navigation-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: baseline;
  }

  .dark-mode__options {
    order: 1;
  }
}

.body-wrapper.dark-mode #education-wrapper p {
  color: #fff !important;
}

.body-wrapper.dark-mode #experience-wrapper {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("./static/images/webb.png");;
}

.body-wrapper.dark-mode #introduction {
  background: rgba(17,41,102,0.4);
}

.body-wrapper.dark-mode #introduction p {
  color: #fff;
  opacity: 1;
}

.body-wrapper.dark-mode .welcome-text-left span {
  color: lightblue;
}

#privacy-consent-wrapper {
  z-index: 100;
  display: none;
  flex-direction: column;
  padding: 10px 25px;
  background-color: coral;
  color: #fff;
  left: 0;
}

.body-wrapper.dark-mode #privacy-consent-wrapper {
  background-color: #40148c;
}

body:not(.dark-mode) #privacy-consent-wrapper a {
  color: #2b62d9;
}

body:not(.dark-mode) #privacy-consent-wrapper a:hover {
  color: #00526e;
}

#privacy-consent-wrapper p {
  width: 90%;
}

#privacy-consent-wrapper .privacy-consent__buttons {
  width: 90%;
}

@media(min-width: 768px) {
  #privacy-consent-wrapper {
    flex-direction: row;
  }

  #privacy-consent-wrapper p {
    width: 80%;
  }
  
  #privacy-consent-wrapper .privacy-consent__buttons {
    width: 20%;
    padding: 10px;
  }
}

.btn-nav {
  padding: 0.25rem 0.5rem;
  font-size: 1.12rem;
  line-height: 2.24;
  border-radius: 0;
}

@media(min-width: 1200px) {
  .btn-nav {
    font-size: 1.25rem;
    line-height: 2.5;
  }
}