.gameArea {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.gameData {
    text-align: center;
}

.winningMsg, .losingMsg {
    font-size: 1.1rem;
}

.winningMsg {
    color: #3c9a5f;
}

.losingMsg, .errorMessage {
    color: darkred;
}

.buttonsWrapper > div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 10px 0;
}

.computerCup, .compGoal, .humanGoal {
    pointer-events: none;
}

.humanGoal {
    background-color: gray;
    color: white;
}

.cupsWrapper {
    display: flex;
    flex-wrap: wrap;
}

.cupsWrapper button {
    width: calc(100%/6);
}