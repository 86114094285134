.giftWrapper {
  position: relative;
  width: 75px;
  height: 75px;
  margin: 0 10px;
  margin-bottom: -5px;
}

.giftWrapper:hover {
  cursor: pointer;
}

.giftWrapper.closed .giftBg {
  position: absolute;
  bottom: 5px;
  left: 5px;
  width: 65px;
  height: 45px;
  background-color: #333;
  border-radius: 10px;
}

.giftWrapper.opened .giftBg {
  position: absolute;
  bottom: 12px;
  left: 10px;
  width: 74px;
  height: 53px;
  background-color: #333;
  border-radius: 10px;
}

.giftWrapper :global(.fas) {
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.confettiWrapper img {
  opacity: 0;
  animation-name: confettiAnim;
  animation-duration: 3s;
}

@keyframes confettiAnim {
  0% { opacity: 1; margin-top: -60px; margin-right: -100px; margin-left: -30px; transform: rotate(20deg); }
  10% { margin-top: -250px; }
  50% { transform: rotate(-20deg); }
  60% { opacity: 1 }
  100% { margin-top: 0px; opacity: 0; margin-right: -20px; margin-left: 0px; }
}