.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1021;
}

.modal {
    position: fixed;
    top: 5%;
    left: 2.5%;
    width: 95%;
    z-index: 1022;
}
.modal-content {
    background-color: #fefefe;
    padding: 1rem;

  border-radius: 12px;
  text-align: center;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
}

@media(min-width: 992px) {
    .modal {
        left: 50%;
        margin-left: -25rem;
        width: 50rem;
        max-width: 85%;
    }
}

.modal:global(.dark-mode) .modal-content {
    background-color: #333;
    color: #fff;
}

.modal:global(.dark-mode) .modal-content input, .modal:global(.dark-mode) .modal-content select, .modal:global(.dark-mode) .modal-content textarea {
    background-color: rgba(51,51,68,.26666666666666666);
    color: #fff;
}