.new-expense__controls {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
  text-align: left;
}

.new-expense__control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}

.new-expense__control {
  width: 95%;
  padding: 0 10px;
}

@media(min-width: 992px) {
  .new-expense__control {
    width: 45%;
  }
}

.new-expense__control input {
  font: inherit;
  padding: 0.5rem;
  border-radius: 6px;
  border: 1px solid #ccc;
  width: 100%;
}

.new-expense__actions {
  text-align: right;
}

.new-expense__control input.invalid-input {
  background: salmon;
  border: 1px solid red;
}

.hidden {
  display: none;
}

.select {
  font-size: 1.3rem;
}

.description-textarea {
  width: 100%;
}

.description-textarea__input {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}