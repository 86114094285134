#dragonTreeImgContainer .slide {
  visibility: hidden;
}

#dragonTreeImgContainer .slide:nth-child(1) {
  animation-name: toggleVisibility;
  animation-duration: 1s;
  animation-iteration-count: 1;
}
#dragonTreeImgContainer .slide:nth-child(2) {
  animation-name: toggleVisibility;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-delay: 1s;
}
#dragonTreeImgContainer .slide:nth-child(3) {
  animation-name: toggleVisibility;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-delay: 2s;
}
#dragonTreeImgContainer .slide:nth-child(4) {
  animation-name: toggleVisibility;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-delay: 3s;
}
#dragonTreeImgContainer .slide:nth-child(5) {
  animation-name: toggleVisibility;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-delay: 4s;
}
#dragonTreeImgContainer .slide:nth-child(6) {
  animation-name: toggleVisibility;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-delay: 5s;
}
#dragonTreeImgContainer .slide:nth-child(7) {
  animation-name: toggleVisibility;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-delay: 6s;
}
#dragonTreeImgContainer .slide:nth-child(8) {
  animation-name: toggleVisibility;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-delay: 7s;
}
#dragonTreeImgContainer .slide:nth-child(9) {
  animation-name: toggleVisibility;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-delay: 8s;
}
#dragonTreeImgContainer .slide:nth-child(10) {
  animation-name: toggleVisibility;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-delay: 9s;
}
#dragonTreeImgContainer .slide:nth-child(11) {
  animation-name: eatingAnim;
  animation-duration: 1s;
  animation-iteration-count: 3;
  animation-delay: 10s;
}
#dragonTreeImgContainer .slide:nth-child(12) {
  animation-name: eatingAnim;
  animation-duration: 1s;
  animation-iteration-count: 3;
  animation-delay: 10.5s;
}
#dragonTreeImgContainer .slide:nth-child(13) {
  animation-name: toggleVisibility;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-delay: 13s;
}
#dragonTreeImgContainer .slide:nth-child(14) {
  animation-name: toggleVisibility;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-delay: 14s;
}
#dragonTreeImgContainer .slide:nth-child(15) {
  animation-name: toggleVisibility;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-delay: 15s;
}
#dragonTreeImgContainer .slide:nth-child(16) {
  animation-name: toggleVisibility;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-delay: 16s;
}
#dragonTreeImgContainer .slide:nth-child(17) {
  animation-name: toggleVisibility;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-delay: 17s;
}
#dragonTreeImgContainer .slide:nth-child(18) {
  animation-name: toggleVisibility;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-delay: 18s;
}
#dragonTreeImgContainer .slide:nth-child(19) {
  animation-name: toggleVisibility;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-delay: 19s;
}
#dragonTreeImgContainer .slide:nth-child(20) {
  animation-name: toggleVisibility;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-delay: 20s;
}
#dragonTreeImgContainer .slide:nth-child(21) {
  animation-name: endAnim1;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-delay: 21s;
}
#dragonTreeImgContainer .slide:nth-child(22) {
  animation-name: endAnim1;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-delay: 22s;
}
#dragonTreeImgContainer .slide:nth-child(23) {
  animation-name: endAnim1;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-delay: 23s;
}
#dragonTreeImgContainer .slide:nth-child(24) {
  animation-name: endAnim1;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-delay: 24s;
}

@keyframes toggleVisibility {
  0% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
}

@keyframes eatingAnim {
  0% {
    visibility: visible;
  }
  50% {
    visibility: hidden;
  }
}

@keyframes endAnim1 {
  0% {
    visibility: visible;
  }
  25% {
    visibility: hidden;
  }
}
