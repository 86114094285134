.gc {
    opacity: 0.5;
    background: black;
    margin-left: auto;
    margin-right: auto;
}
@media(max-width:992px) {
    .gc {
        margin-left: 10%;
        margin-right: 10%;
    }
}
.crash {
    color: red;
}
.game-wrapper, .try-again-block {
    display: flex;
    flex-direction: column;
}
.game-wrapper {
    padding-bottom: 15px;
}
.try-again-block {
    align-items: center;
}
@media(min-width:992px) {
    .mobile-br {
        display: none;
    }
}
@media(max-width:992px) {
    #your-phone-text {
        padding-left: 10px;
    }
    .sticky-btn-wrap {
        top: 65%;
    }
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.desc {
    padding-bottom: 5px;
}

.gameUI {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

.crashText {
    display: none;
    color: red;
}