.expenses-filter {
  color: white;
  padding: 0 1rem;
}

.expenses-filter__control {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  margin: 1rem 0;
}

@media(min-width: 992px) {
  .expenses-filter__control {
    align-items: center;
  }
}

.expenses-filter label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #111111;
}

:global(.body-wrapper.dark-mode) .expenses-filter label {
  color: #fff;
}

.expenses-filter select {
  font: inherit;
  padding: 0.5rem 3rem;
  font-weight: bold;
  border-radius: 6px;
}

:global(.body-wrapper.dark-mode) .expenses-filter select {
  color: #fff;
  background-color: #333;
}
