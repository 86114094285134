

:global(.body-wrapper.dark-mode) .new-expense input, :global(.body-wrapper.dark-mode) .new-expense textarea, :global(.body-wrapper.dark-mode) .new-expense select {
  background-color: #3344;
  color: #fff;
}

.new-expense button {
  font: inherit;
  font-weight: 700;
  cursor: pointer;
  padding: 1rem 2rem;
  border: 1px solid #e91c24;
  background-color: #e91c24;
  color: white;
  border-radius: 12px;
  margin-right: 1rem;
}

:global(.body-wrapper.dark-mode) .new-expense button {
  border: 1px solid #40148c;
  background-color: #40148c;
}

.new-expense button:hover,
.new-expense button:active {
  background-color: #111111;
  border-color: #111111;
}

:global(.body-wrapper.dark-mode) .new-expense button:hover,
:global(.body-wrapper.dark-mode) .new-expense button:active {
  background-color: #111111;
  border-color: #111111;

}

.new-expense button.alternative {
  color: #220131;
  border-color: transparent;
  background-color: transparent;
}

.new-expense button.alternative:hover,
.new-expense button.alternative:active {
  background-color: #ddb3f8;
}
